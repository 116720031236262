<template>
    <div class="aftersalesadd-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="新　增" to="/client/aftersales/add" />
                <van-tab replace title="审批中" to="/client/aftersales/waiting" />
                <van-tab replace title="已完成" to="/client/aftersales/completed" />
            </van-tabs>
        </div>
        <div class="aftersalesadd-body">
            <div class="ship-item">
                <gk-select
                    v-model:active="orderPicker.active"
                    v-model:show="orderPicker.show"
                    v-model:value="orderPicker.value"
                    :columns="orderPicker.list"
                    label="发货单"
                    @confirm="confirmOrder"
                />
            </div>

            <div class="inventory-item">
                <div class="title-item">
                    <div class="vertical-block"></div>
                    <p>售后清单</p>
                </div>
                <van-checkbox-group v-model="poleChecked">
                    <van-swipe-cell v-for="(item, index) in poleOptions" class="single-item">
                        <van-checkbox :name="item.id" checked-color="#DF5F0B" icon-size="0.16rem" shape="square">
                            <van-cell :border="false" :title="item.production"></van-cell>
                        </van-checkbox>
                    </van-swipe-cell>
                </van-checkbox-group>
                <div class="title-item">
                    <div class="vertical-block"></div>
                    <p>现场图片</p>
                </div>
                <div class="imageupload-item">
                    <gk-image-uploader v-model:image="image" />
                </div>
                <div class="title-item">
                    <div class="vertical-block"></div>
                    <p>描述问题：</p>
                </div>
                <van-cell-group inset style="margin-bottom: 20px">
                    <van-field
                        v-model="dataForm.remark"
                        autosize
                        class="diybg"
                        maxlength="250"
                        placeholder="请输入售后的内容..."
                        rows="2"
                        show-word-limit
                        type="textarea"
                    />
                </van-cell-group>
                <div class="submit-button">
                    <van-button :block="true" color="#DF5F0B" @click="onSubmit">确认提交售后</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ADD_GOODS_AFTER_SALE, GET_GOODS_RECEIVE_LIST, GET_GOODS_RECEIVE_POLE_DETAIL } from "@/api/goods";
import { Toast } from "vant";
import usePublic from "@/hooks/usePublic";

let { $route, $router } = usePublic();

onMounted(() => {
    getOrderList();
    if ($route.query.id && $route.query.text) {
        orderPicker.value.active = {
            id: $route.query.id,
            text: $route.query.text
        };
        dataForm.value.shipment_id = $route.query.id;
        confirmOrder({ id: $route.query.id });
    }
});

// tab切换
let tabActive = ref(0);

// 图片
let image = ref(null);

// 订单选择器
let getOrderList = () => {
    GET_GOODS_RECEIVE_LIST({
        type: "have",
        limit: 10000
    }).then((res) => {
        orderPicker.value.list = res.data.data.list.map((item) => {
            return {
                id: item.shipment_id,
                text: item.order.order_sn
            };
        });
    });
};

let orderPicker = ref({
    show: false,
    value: "选择发货单",
    active: {},
    list: []
});

let confirmOrder = (val) => {
    poleChecked.value = [];
    dataForm.value.shipment_id = val.id;
    GET_GOODS_RECEIVE_POLE_DETAIL({ id: val.id }).then((res) => {
        console.log(res.data.data);
        poleOptions.value = res.data.data;
    });
};

// 售后电杆
let poleOptions = ref([]);
let poleChecked = ref([]);

// 表单数据
let dataForm = ref({
    shipment_id: null,
    remark: null
});

let onSubmit = () => {
    ADD_GOODS_AFTER_SALE({
        ...dataForm.value,
        poles: poleChecked.value,
        images: [image.value]
    }).then((res) => {
        if (res.data.code === 200) {
            Toast({
                type: "success",
                message: "新增成功",
                onClose: () => {
                    $router.push({ path: "/client/aftersales/waiting" });
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.aftersalesadd-page {
    min-height: 100vh;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .aftersalesadd-body {
        margin-top: 10px;

        .ship-item {
            background: #fff;
            min-height: 50px;
            display: flex;
            align-items: center;

            :deep(.gk-select) {
                width: 100%;

                .van-cell {
                    .van-cell__title {
                        flex: 0 0 80px;
                    }
                }
            }

            .custom-title {
                font-size: 14px;
                color: #000;
            }

            .custom-right {
                font-size: 14px;
                color: #df5f0b;
            }
        }

        .inventory-item {
            margin-top: 10px;
            min-height: 86vh;
            background: #fff;

            .title-item {
                padding: 20px 15px 10px 15px;
                font-size: 14px;
                font-weight: bold;
                display: flex;
                flex-direction: row;
                align-items: center;

                .vertical-block {
                    width: 3px;
                    height: 10px;
                    background: #df5f0b;
                    margin-right: 10px;
                }
            }

            .single-item {
                padding: 0 15px;
                border-bottom: dashed 1px #eee;

                .van-cell {
                    padding-left: 0;
                }
            }

            .imageupload-item {
                padding: 0 15px;

                :deep(.gk-image-uploader) {
                    width: 90px;
                    height: 80px;

                    .van-uploader {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .diybg {
                background: #eee;
            }

            .submit-button {
                padding: 14px;
            }
        }
    }
}
</style>
